.CardviewS3C .gradient-top {
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 2;
    top: 0;
    rotate: 180deg;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 69.1%);
}
.CardviewS3C .gradient-bot {
    position: absolute;
    width: 100%;
    height: 150px;
    z-index: 2;
    bottom: 0;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.7) 51.66%, rgba(255, 255, 255, 0) 91.23%);
    backdrop-filter: blur(0.4px);
}
