@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");
.HeroCardC .title {
  font-family: 'Optima';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110px; }

.HeroCardC .sub-title-text {
  color: #374957;
  font-family: 'Optima';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px; }

.HeroCardC .arrowBtn {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center; }

.HeroCardC .cardSection {
  font-family: 'Optima'; }

.HeroCardC .cardSection h5 {
  font-family: 'Branch'; }

.HeroCardC .cardSection .priceTag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  background: rgba(0, 0, 0, 0.05); }

.HeroCardC .cardSection .off {
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-left: 8px; }

.HeroCardC .cardSection .line {
  border: 1px dashed #545650;
  margin: 54px 0; }

.HeroCardC .cardSection .type {
  color: #363853; }

.HeroCardC .cardSection .type span {
  color: silver; }

.HeroCardC .cardSection .listing {
  display: inline-block;
  padding: 0px 0px 8px;
  gap: 16px;
  font-size: 24px;
  line-height: 28px;
  color: #545650;
  border-bottom: 1px solid #D7DBFE;
  margin-top: 58px; }

.HeroCardC .backgroundSection {
  background: url("../../../images/Rectangle 26 (1).svg") no-repeat;
  background-size: cover; }

.HeroCardC .content {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(17px); }
