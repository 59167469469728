.FooterS2B {
  color: #545650;
  background: white;
  font-family: 'Crimson Text';
  font-weight: 400;
  font-size: 14px; }
.FooterS2B .f-nav {
  background: white;
  padding: 32px;
  border-top: 1px solid #E8EBFF; }
.FooterS2B .f-nav ul {
  display: flex;
  gap: 32px;
  width: 100%;
  flex-wrap: wrap; }
.FooterS2B .f-nav ul li {
  align-items: center;
  gap: 10px;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap; }
.FooterS2B .footer-main {
  padding: 48px; }
.FooterS2B .footer-main .footer-title {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px; }
.FooterS2B .footer-bottom {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-top: 1px solid #606060; }
