@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');


.ImageOnlyM .secondImage {
  background: url("../../../images/Footer (1).svg") no-repeat;
  background-size: cover;
}

.ImageOnlyM .designBackground {
  background: rgba(244, 245, 255, 0.6);
  backdrop-filter: blur(22px);
}

.ImageOnlyM .ImageOnlyM {
  font-family: "Playfair Display", Serif;
}
