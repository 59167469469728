@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
.HeroCardM .title {
  font-family: 'Lato' , sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110px; }

.HeroCardM .sub-title-text {
  color: #374957;
  font-family: 'Lato' , sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px; }

.HeroCardM .arrowBtn {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(84, 86, 80, 0.4);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center; }

.HeroCardM section .cardSection:nth-child(2) .arrowBtn {
  background-color: #54565066; }

.HeroCardM .cardSection:first-of-type {
  background-color: #F8F9FF; }

.HeroCardM .cardSection {
  font-family: 'Lato' , sans-serif; }
.HeroCardM .cardSection h5 {
  font-family: 'Branch'; }
.HeroCardM .cardSection .priceTag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  color: #9DA6FA; }
.HeroCardM .cardSection .off {
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-left: 8px; }
.HeroCardM .cardSection .line {
  border: 1px dashed silver;
  margin: 32px 0;
  width: 100%; }
.HeroCardM .cardSection .type {
  color: #363853; }
.HeroCardM .cardSection .type span {
  color: silver; }
.HeroCardM .cardSection .listing {
  text-align: center;
  padding: 12px 24px;
  color: #fff;
  background: #D7DBFE;
  border-bottom: 1px solid #D7DBFE;
  border-radius: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-top: 65px; }

.HeroCardM .backgroundSectionFirst {
  background: url("../../../images/Rectangle_27.svg") no-repeat;
  background-size: cover; }

.HeroCardM .backgroundSectionSecond {
  background: url("../../../images/Rectangle_27.svg") no-repeat;
  background-size: cover; }

.HeroCardM .content {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(17px); }
