.InstagramS2M .title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  color: #fff;
  color: #363853; }
@media only screen and (min-width: 990px) {
  .InstagramS2M .title {
    font-size: 40px; } }

.InstagramS2M .btn {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  gap: 10px;
  place-items: center;
  display: grid;
  width: 279px;
  height: 37px;
  background: #9DA6FA;
  border-radius: 8px; }
