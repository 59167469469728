@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.ContactUsM .title {
  font-family: "Optima";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110px;
}

.ContactUsM .sub-title-text {
  color: #374957;
  font-family: "Optima";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}

.ContactUsM .helpSection:nth-of-type(1) {
  background-color: #f8f9ff;
  font-family: "Lato", sans-serif;
  color: #000;
}
.ContactUsM .helpSection:nth-of-type(1) .contactInput {
  background-color: #e8ebff;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 9px 17px;
  border: none;
  outline: none;
  color: #000;
  margin-top: 24px;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(1) .contactInput::placeholder {
  color: #000;
}
.ContactUsM .helpSection:nth-of-type(1) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: #9da6fa;
  color: #fff;
  margin-top: 33px;
  border-radius: 8px;
}

.ContactUsM .helpSection:nth-of-type(2) {
  background-color: #fff;
  font-family: "Lato", sans-serif;
  color: #000;
  border: 1px solid #bec4fe;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(2) .contactInput {
  background-color: #fff;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 9px 17px;
  border: none;
  outline: none;
  color: #000;
  margin-top: 24px;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(2) .contactInput::placeholder {
  color: #000;
}
.ContactUsM .helpSection:nth-of-type(2) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: #9da6fa;
  color: #fff;
  margin-top: 33px;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(2) .contactInput {
  border: 1px solid #bec4fe;
}

.ContactUsM .helpSection:nth-of-type(3) {
  background-color: #fff;
  font-family: "Lato", sans-serif;
  color: #000;
}
.ContactUsM .helpSection:nth-of-type(3) .contactInput {
  background-color: #fff;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 9px 17px;
  border: none;
  outline: none;
  color: #000;
  margin-top: 24px;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(3) .contactInput::placeholder {
  color: #000;
}
.ContactUsM .helpSection:nth-of-type(3) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: #9da6fa;
  color: #fff;
  margin-top: 33px;
  border-radius: 8px;
}
.ContactUsM .helpSection:nth-of-type(3) .contactInput {
  border: 1px solid #bec4fe;
}
