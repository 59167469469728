.TextImageS3B {
  font-family: 'crimson'; }
.TextImageS3B .since {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #A2A798; }
.TextImageS3B .bg-text {
  font-style: normal;
  font-weight: 900;
  line-height: 171px;
  opacity: 0.2; }
.TextImageS3B .title {
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
  /* White */ }
