@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.HeaderS3M {
  font-family: "Playfair Display", serif; }
.HeaderS3M.headerBackground {
  width: 100%;
  background-size: cover; }
.HeaderS3M .des {
  font-family: "Roboto", sans-serif; }
.HeaderS3M .rightBackground {
  background: url("../../../../images/Group 56.jpg") no-repeat;
  background-size: cover;
  background-position: center; }
