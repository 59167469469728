.InstagramS3C .title {
  font-family: 'Optima';
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  text-align: center; }
@media only screen and (min-width: 990px) {
  .InstagramS3C .title {
    font-size: 40px; } }

.InstagramS3C .btn {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  gap: 10px;
  place-items: center;
  display: grid;
  width: 279px;
  height: 37px;
  background: #000000; }
