@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");
.ContactUsB .title {
  font-family: "Optima";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110px;
}

.ContactUsB .sub-title-text {
  color: #374957;
  font-family: "Optima";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}

.ContactUsB .helpSection:nth-of-type(1) {
  background-color: #fff;
  font-family: "Crimson Text", serif;
  color: #545650;
  /* border-bottom: 1px solid #d8d8d8; */
}
.ContactUsB .helpSection:nth-of-type(1) .help {
  font-family: "Fasthand", cursive;
}
.ContactUsB .helpSection:nth-of-type(1) .contactInput {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border: none;
  outline: none;
  color: #545650;
  margin-top: 24px;
  padding: 0px 0px 12px;
  /* border-bottom: 1px solid #d8d8d8; */
}
.ContactUsB .helpSection:nth-of-type(1) .contactInput::placeholder {
  color: #545650;
}
.ContactUsB .helpSection:nth-of-type(1) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: rgba(216, 216, 216, 0.4);
  color: #545650;
  margin-top: 33px;
  border-radius: 8px;
}

.ContactUsB .helpSection:nth-of-type(2) {
  background-color: #fff;
  font-family: "Crimson Text", serif;
  color: #545650;
  background: url("../../../images/Contact Us.svg") no-repeat center center;
}
.ContactUsB .helpSection:nth-of-type(2) .help {
  font-family: "Fasthand", cursive;
}
.ContactUsB .helpSection:nth-of-type(2) .contactInput {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border: none;
  outline: none;
  color: #545650;
  margin-top: 24px;
  padding: 0px 0px 12px;
  /* border-bottom: 1px solid #d8d8d8; */
}
.ContactUsB .helpSection:nth-of-type(2) .contactInput::placeholder {
  color: #545650;
}
.ContactUsB .helpSection:nth-of-type(2) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: rgba(216, 216, 216, 0.4);
  color: #545650;
  margin-top: 33px;
  border-radius: 8px;
}
.ContactUsB .helpSection:nth-of-type(2) form {
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

.ContactUsB .helpSection:nth-of-type(3) {
  background-color: #fff;
  font-family: "Crimson Text", serif;
  color: #545650;
  /* border-bottom: 1px solid #d8d8d8; */
}
.ContactUsB .helpSection:nth-of-type(3) .help {
  font-family: "Fasthand", cursive;
}
.ContactUsB .helpSection:nth-of-type(3) .contactInput {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border: none;
  outline: none;
  color: #545650;
  margin-top: 24px;
  padding: 0px 0px 12px;
  /* border-bottom: 1px solid #d8d8d8; */
}
.ContactUsB .helpSection:nth-of-type(3) .contactInput::placeholder {
  color: #545650;
}
.ContactUsB .helpSection:nth-of-type(3) .contactButton {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 9px 17px;
  gap: 10px;
  background-color: rgba(216, 216, 216, 0.4);
  color: #545650;
  margin-top: 33px;
  border-radius: 8px;
}
